import request from '@/utils/request'

// 查询订单-预约打本订单列表
export function listOrderScript(query) {
  return request({
    url: '/order/order/script/list',
    method: 'get',
    params: query
  })
}

// 查询订单-预约打本订单详细
export function getOrderScript(id) {
  return request({
    url: '/order/order/script/' + id,
    method: 'get'
  })
}

// 新增订单-预约打本订单
export function addOrderScript(data) {
  return request({
    url: '/order/order/script',
    method: 'post',
    data: data
  })
}

// 修改订单-预约打本订单
export function updateOrderScript(data) {
  return request({
    url: '/order/order/script',
    method: 'put',
    data: data
  })
}

// 删除订单-预约打本订单
export function delOrderScript(id) {
  return request({
    url: '/order/order/script/' + id,
    method: 'delete'
  })
}
