<template>
  <a-modal
    :title="title"
    :maskClosable=false
    :width="1000"
    :visible="visible"
    :footer="null"
    @cancel="handleCancel">


    <a-card :bordered="false">

      <!--订单状态步骤条-->
      <a-steps v-if="record&&record.id" :current="this.record.orderStatus"
               size="small">
        <a-step>
          <template slot="title">
            买家下单
          </template>
          <span slot="description">{{ this.record.createTime || '' }}</span>
        </a-step>
        <a-step>
          <template slot="title">
            买家付款
          </template>
          <span slot="description">{{ this.record.payTime || '' }}</span>
        </a-step>
        <a-step>
          <template slot="title">
            评价
          </template>
        </a-step>
        <a-step>
          <template slot="title">
            完成
          </template>
        </a-step>
      </a-steps>

      <!--订单信息块-->
      <div class="order-info">
        <table>
          <tr>
            <td style="width: 310px;vertical-align: top;background-color:#fbfbfb;">
              <a-card size="small" :bordered="false" title="订单信息"
                      :headStyle="{'background-color':'#f3f3f3','height': '29px','border-bottom': '1px solid #DDD','margin-bottom':'0'}"
                      :bodyStyle="{'background-color':'#fbfbfb'}">
                <table class="info-table">
<!--                  <tr>
                    <td class="first">门票编码：</td>
                    <td class="data">
                      {{ this.record.discountAmountInfo.ticketId }}
                    </td>
                  </tr>
                  <tr>
                    <td class="first">门票金额：</td>
                    <td class="data">{{ this.record.discountAmountInfo.ticketAmount }}￥</td>
                  </tr>
                  <tr>
                    <td class="first">优惠编码：</td>
                    <td class="data">
                      {{ this.record.discountAmountInfo.couponId }}
                    </td>
                  </tr>
                  <tr>
                    <td class="first">优惠金额：</td>
                    <td class="data">{{ this.record.discountAmountInfo.couponAmount }}￥</td>
                  </tr>-->
                  <tr>
                    <td class="first">订单编号：</td>
                    <td class="data">
                      {{ this.record.id }}
                      <a-dropdown placement="bottomCenter">
                        <a class="ant-dropdown-link" @click="e => e.preventDefault()">
                          更多
                          <a-icon type="down"/>
                        </a>

                        <div slot="overlay"
                             style="background-color: #FBFBFB;border: 1px solid #ddd;width: 250px;padding: 5px 10px;">
                          <table>
                            <tr>
                              <td>成交时间：</td>
                              <td>{{ this.record.createTime }}</td>
                            </tr>
                            <tr v-if="this.record.payTime">
                              <td>付款时间：</td>
                              <td>{{ this.record.payTime }}</td>
                            </tr>
                          </table>
                        </div>

                      </a-dropdown>
                    </td>
                  </tr>
                  <tr>
                    <td class="first">买家名称：</td>
                    <td class="data">{{ this.record.nickName }}</td>
                  </tr>
                </table>
              </a-card>
            </td>
            <td style="border-left: 1px solid #ddd;">
              <a-card :bordered="false">
                <div class="detailPanel" style="margin:13px 10px;width: 100%;height: auto;">
                  <dl>
                    <dt>
                      <img
                        :src="record.orderStatus!=2130&&record.orderStatus!=2131?'/img/orderInfo/exclamation-point.png':'/img/orderInfo/success.png'">
                    </dt>
                    <dd>
                      <h3>订单状态:{{ record.orderStatus | statusFilter }}</h3>
                    </dd>
                  </dl>
                </div>
              </a-card>
            </td>
          </tr>
        </table>
      </div>

      <a-table
        style="margin-top:20px;"
        rowKey="id"
        :columns="columns"
        :dataSource="data"
        :pagination="false"
        size="middle"
        bordered>

        <!--商品信息-->
        <span slot="goodsInfo" slot-scope="text, record">
            <div>
              <div style="float: left;">
                <img style="width: 50px;height: 50px;"
                     :src="record.cover"
                     preview="剧本主图"
                />
              </div>
              <div style="float: left;margin-left: 10px;">
                <div class="ellipsis" style="width: 280px;text-align: left;">{{ record.name }}</div>
                <div class="ellipsis"
                     style="font-size:12px;color:#acacac;width: 280px;text-align: left;">剧本ID:{{ record.goodsId }}
                  <template v-if="record.playTime">玩本时间:{{ record.playTime }}</template></div>
              </div>
              <div style="clear: both;"></div>
            </div>
        </span>

      </a-table>


      <div style="margin-top:20px;background-color: #f3f3f3;">
        <table class="order-money">
          <tr>
            <td class="om-c1">订单单价：</td>
            <td class="om-c1">￥{{ this.record.payPrice }}</td>
          </tr>
          <tr>
            <td class="om-c1">订单总价：</td>
            <td class="om-c1">￥{{ this.record.payAmount }}</td>
          </tr>
          <tr v-if="record.orderStatus!=2150">
            <td class="om-c2">{{ '实收款：' }}</td>
            <td class="om-c3">￥{{ this.record.payAmount }}</td>
          </tr>
        </table>

        <div style="clear: both;"></div>
      </div>
    </a-card>
  </a-modal>
</template>

<script>
import STable from '@/components/table/'
import DetailList from '@/components/tools/DetailList'
import ABadge from "ant-design-vue/es/badge/Badge"
import moment from "moment"
import {getOrderScript} from '@/api/order/orderScript'
import {mapGetters} from 'vuex'
import CustomDictTag from "@/components/DictCustomTag";

const DetailListItem = DetailList.Item;

export default {
  name: "OrderInfoModal",
  components: {
    ABadge,
    DetailList,
    DetailListItem,
    CustomDictTag,
    STable
  },
  data() {
    return {
      visible: false,
      data: null,
      record: null,
      toggleSearchStatus: false,
      columns: [
        {
          title: '剧本名称',
          align: "center",
          dataIndex: 'name',
          width: 400,
          scopedSlots: {customRender: 'goodsInfo'},
        },
        {
          title: '单价',
          align: "center",
          dataIndex: 'payPrice',
        },
        {
          title: '数量',
          align: "center",
          dataIndex: 'orderCount'
        },
        {
          /* title: '规格',
           align: "center",
           dataIndex: 'skuName',*/
          // customRender: function (t, r, index) {
          //     if(r.couponMoney>0){
          //         return "优惠券减免金额"+r.couponMoney;
          //     }
          //     if(r.remissionShippingMoney>0){
          //         return "运费券减免金额"+r.remissionShippingMoney;
          //     }
          //     return '';
          // }
        },
        /*{
            title: '状态',
            align: "center",
            dataIndex: 'refundStatus',
            customRender: function (t, r, index) {
                if(t!=0){
                    switch (t) {
                        case 1:
                            return "申请中";
                        case 2:
                            return "已拒绝退款";
                        case 3:
                            return "退款成功";
                        case 5:
                            return "等待卖家处理退货申请";
                        case 6:
                            return "等待买家发货";
                        case 7:
                            return "等待卖家收货";
                        case 8:
                            return "退款中";
                    }
                }
                //订单状态1待付款 2待发货 3待收货 4待评价 5已完成 6已关闭 0已取消
                switch (r.orderStatus) {
                    case 6:
                        return "已取消";
                    case 1:
                        return "待付款";
                    case 2:
                        return "待发货";
                    case 3:
                        return "待收货";
                    case 4:
                        return "待评价";
                    case 5:
                        return "已完成";
                    case 7:
                        return "已关闭";
                }

            }
        }*/
      ],
      endaffirmDate: ''
    }
  },
  created() {
  },
  filters: {
    statusFilter(status) {
      const statusMap = {
        2110: '代付款',
        2120: '待验券',
        2130: '已完成(未评价)',
        2131: '已完成',
        2140: '已退款',
        2141: '退款申请中',
        2150: '已取消'
      };
      return statusMap[status]
    }
  },
  computed: {
    ...mapGetters(['customDict']),
  },
  methods: {
    success() {
      this.$message.success('复制成功');
    },
    info(id) {
      var that = this;
      getOrderScript(id).then(response => {
        console.log(response)
        that.record = response.data;
        that.data = response.data;
        that.visible = true;
      });
    },
    handleCancel() {
      this.visible = false;
    },

  }
}
</script>

<style lang="less" scoped>
.title {
  color: rgba(0, 0, 0, .85);
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 16px;
}

.message-text {
  font-family: MicrosoftYaHei;
  font-size: 1rem;
  font-weight: normal;
  font-stretch: normal;
  line-height: 3rem;
  letter-spacing: 0rem;
  color: #333333;
  width: 50%;
}

.fontblack {
  color: #999999
}

.img2 {
  width: .81rem;
  height: .8rem;
  float: right;
}

.addressshow2 {
  height: auto;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  width: 75%;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: normal;
  word-wrap: break-word;
  word-break: break-all;
  font-size: 1rem;
}

.addressshow1 {
  height: auto;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  width: 75%;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  white-space: normal;
  word-wrap: break-word;
  word-break: break-all;
  font-size: 1rem;
}

.orderTitle {
  font-size: 1rem;
  color: #333333;
  height: auto;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: normal;
  word-wrap: break-word;
  word-break: break-all;
  height: 2.5rem;
}

.orderDetail {
  font-size: 0.8rem;
  color: #666666;
  text-align: left;
}

.border-ceter {
  width: 92%;
  padding-left: 15px;
  padding-right: 15px;
}

.pay-button {
  width: 88%;
  height: 2.6rem;
  position: relative;
  background-color: red;
  color: white;

  margin-left: 6%;
}

ul li {
  list-style: none;
  font-size: 1rem;
}

ul {
  padding-left: 1.5rem
}

.track-rcol {
}

.track-list {
  position: relative;
}

.track-list li {
  position: relative;
  padding: 0px 0 1.5rem 25px;
  line-height: 1rem;
  border-left: 1px solid #d9d9d9;
  color: #999;
}

.track-list li.first {
  color: red;
  padding-top: 0;
  width: 100%;
  text-align: left;
  border-left: 1px solid #d9d9d9;
}

.track-list li .node-icon {
  position: absolute;
  left: -5.5px;
  border-radius: 0.25rem;
  width: 0.5rem;
  height: 0.5rem;
  top: 4px;
  background-color: #999999;
}

.track-list li.first .node-icon {
  background-position: 0-72px;
  background-color: red;
  width: 1rem;
  z-index: 2;
  height: 1rem;
  position: absolute;
  left: -9px;
  top: 0;
  border-radius: 0.5rem;
}

.track-list li .time {
  margin-right: 20px;
  position: relative;
  top: 4px;
  display: inline-block;
  vertical-align: middle;
  background-color: white;
  color: #999;
  width: 100%;
  text-align: left;
}

.track-list li .txt {
  position: relative;
  display: inline-block;
  vertical-align: top;
  color: #999;
}

.track-list li.first .time {
  text-align: left;
  width: 94%;
  color: red;
}

.track-list li.first .txt {
  color: red;
  text-align: left;
  width: 94%;
}

.track-list li.finall {
  position: relative;
  padding: 0px 0 1.5rem 25px;
  line-height: 18px;
  border-color: white;
  border-left: 1px solid #ffffff;
  color: #999;
}

.track-list li.finall .div-spilander {
  width: 1px;
  position: absolute;
  position: absolute;
  left: -1.5px;
  height: 0.5rem;
  background-color: #d9d9d9;
}

.order-info {
  border: 1px solid #e8e8e8;
  margin-top: 40px;

  .info-table {
    td {
      display: table-cell;
      vertical-align: top;
      font-size: 13px;
    }

    .first {
      width: 85px;
    }

    .data {
      width: 215px;
    }
  }
}

.detailPanel {
  dd, dt {
    display: inline-block;
    vertical-align: center;
  }

  dt img {
    margin: 0 20px 5px 0px;
  }

  dd {
    h3 {
      font-weight: bold;
    }
  }

  .trade-detail-prompt {
    margin-left: 35px;

    li {
      font-size: 13px !important;

      .dotted-node {
        padding: 0 5px;
      }
    }
  }
}

.order-money {
  .om-c1 {
    font-size: 14px;
    color: #666
  }

  .om-c2 {
    font-size: 16px;
    color: #333;
    font-weight: bold;
  }

  .om-c3 {
    font-size: 16px;
    color: #DE3636;
    font-weight: bold;
  }

  td {
    text-align: right;
  }

  float: right;
  margin: 20px 40px 20px 0;
}
</style>